@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
  
  body {
    @apply bg-gray-50 text-dark-800 dark:bg-dark-900 dark:text-gray-100;
    font-family: 'Inter', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-heading font-bold;
  }
}

@layer components {
  .btn {
    @apply inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors;
  }
  
  .btn-primary {
    @apply bg-primary-600 text-white hover:bg-primary-700 focus:ring-primary-500;
  }
  
  .btn-secondary {
    @apply bg-secondary-600 text-white hover:bg-secondary-700 focus:ring-secondary-500;
  }
  
  .btn-outline {
    @apply border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-primary-500;
  }
  
  .card {
    @apply bg-white dark:bg-dark-800 rounded-lg shadow-card overflow-hidden transition-shadow hover:shadow-card-hover;
  }
  
  .input {
    @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm;
  }
  
  .container-custom {
    @apply max-w-7xl mx-auto px-4 sm:px-6 lg:px-8;
  }
}

/* Player de música personalizado */
.audio-player {
  @apply bg-dark-800 text-white rounded-lg shadow-lg p-4;
}

.audio-player-controls {
  @apply flex items-center justify-between;
}

.audio-player-progress {
  @apply w-full bg-gray-700 rounded-full h-1 my-2 cursor-pointer;
}

.audio-player-progress-bar {
  @apply bg-primary-500 h-1 rounded-full;
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

/* Modo escuro */
.dark .dark\:invert {
  filter: invert(1);
}

/* Scrollbar personalizada */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100 dark:bg-dark-800;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 dark:bg-dark-600 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500 dark:bg-dark-500;
}